<template>
  <footer class="footer">
    <div class="footer-top">
      <a href="#" class="footer__logo link">
        <img class="" src="img/logo.png" alt="">
      </a>
      <span class="footer__title text text--big">Есть вопросы?</span>
      <button class="btn btn--order"><a href="https://wa.me/79869322789" target="_blank">Наш WhatsApp</a></button>
    </div>
    <div class="footer-bottom">
      <ul class="footer__list">
        <li class="footer__item">
          <a href="https://gospts.ru/" class="footer__link link link--footer" target="_blank">Главная</a>
        </li>
        <li class="footer__item">
          <a href="https://reg.gospts.ru/?from=gospts#/" class="link link--footer" target="_blank">Оформление ЭПТС</a>
        </li>
        <li class="footer__item">
          <a href="https://gospts.ru/affiliate/" class="link link--footer" target="_blank">Сотрудничество</a>
        </li>
        <li class="footer__item">
          <a href="https://gospts.ru/about/" class="link link--footer" target="_blank">О сервисе</a>
        </li>
        <li class="footer__item">
          <a href="https://gospts.ru/contacts/" class="link link--footer" target="_blank">Контакты</a>
        </li>
      </ul>
      <ul class="footer__list">
        <li class="footer__item">
          <a href="" class="footer__link link link--footer">Полезное</a>
        </li>
        <li class="footer__item">
          <a href="https://gospts.ru/politic.pdf" class="link link--footer" target="_blank">Политика конфиденциальности</a>
        </li>
        <li class="footer__item">
          <a href="https://gospts.ru/politic.pdf" class="link link--footer" target="_blank">Публичная офера</a>
        </li>
      </ul>
      <ul class="footer__list">
        <li class="footer__item">
          <a href="" class="footer__link link link--footer">Помощь</a>
        </li>
        <li class="footer__item">
          <a href="https://gospts.ru/about/" class="link link--footer" target="_blank">Вопросы и ответы</a>
        </li>
        <li class="footer__item">
          <a href="tel:89869322789" class="link link--footer"><strong>Приемная: +7(986)932-27-89</strong></a>
        </li>
        <li class="footer__item">
          <a href="tel:89872969111" class="link link--footer"><strong>Техосмотр: +7(987) 296-91-11</strong></a>
        </li>
        <li class="footer__item" style="margin-top: 30px">
          <a href="https://solution-agency.ru/" class="link link--footer" target="_blank">Разработка SOLUTION AGENCY</a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>